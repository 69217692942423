import jump from 'jump.js';
import { isElementVisible } from './visibility';

export function scrollToRef(ref, options = {}) {
  const { duration = 500, offset = 0, visibilityCheck = true } = options;

  const jumpOptions = {
    duration,
    offset,
  };

  if (typeof window !== 'undefined') {
    if (visibilityCheck) {
      if (!isElementVisible(ref.current)) {
        return jump(ref.current, jumpOptions);
      }
    } else {
      return jump(ref.current, jumpOptions);
    }
  }
}
