import React from 'react';
import { Link } from 'gatsby';

const LinkButton = ({ slug, children, url, external, className, alt, small, ...rest }) => {
  let cssClass = 'button';

  if (alt) {
    cssClass = `${cssClass} button--alt`;
  }

  if (small) {
    cssClass = `${cssClass} button--small`;
  }

  if (className) {
    cssClass = `${cssClass} ${className}`;
  }

  if (external && url) {
    return (
      <a className={cssClass} href={url} {...rest} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return (
    <Link className={cssClass} to={slug} {...rest}>
      {children}
    </Link>
  );
};

export default LinkButton;
